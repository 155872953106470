import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './style.css';

export default function Error() {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = '404 | Error';
      });

    return (
        <div id="page-container" className="main-content-boxed">
            <main id="main-container">
                <div className="hero bg-body-extra-light">
                    <div className="hero-inner">
                        <div className="content content-full">
                            <div className="py-4 text-center">
                                <div className="display-1 fw-bold text-danger">
                                    <i className="fa fa-exclamation-triangle opacity-100 me-1"></i> 404
                                </div>
                                <h1 className="fw-bold mt-5 mb-2 text-white display-3">Oops.. You just found an error page..</h1>
                                <h2 className="fs-4 fw-medium text-muted mb-5">We are sorry but the page you are looking for was not found..</h2>
                                <Link className="btn btn-lg bgg" to="/">
                                    <i className="fa fa-arrow-left opacity-50 me-1"></i> Back to Home Page
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}
