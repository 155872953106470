import React, { useState } from 'react';
import Icon from '@mdi/react';
import { mdiGoogleMaps, mdiEmail, mdiPhone } from '@mdi/js';

export default function ContactUs() {

    const [credentials, setCredentials] = useState({
        name: "",
        email: "",
        subject: "",
        comment: ""
    });

    const [spinner, setSpinner] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        setSpinner(true);
        if(credentials.name === ''){
            setError("Name is required");
            return
        }
        else if(credentials.email === ''){
            setError("Email is Required");
            return
        }
        else if(credentials.subject === ''){
            setError("Subject is Required");
            return
        }
        else if(credentials.comment === ''){
            setError("Message is Required");
            return
        }
        else{
            console.log(credentials.name + ' ' + credentials.email + ' ' + credentials.subject + ' ' + credentials.comment);
            fetch("https://veel.ai/server/mail.php", {
                method: "POST",
                headers: {
                  "content-type": "application/json",
                  accept: "application/json",
                },
                body: JSON.stringify({
                  name: credentials.name,
                  email: credentials.email,
                  subject: credentials.subject,
                  comment: credentials.comment
                }),
              })
                .then(response => response.json())
                .then((response) => {
                  if(response.success === true){
                    setSpinner(false);
                    setSuccess('Email Sent Successfully');
                    setCredentials({
                        name: "",
                        email: "",
                        subject: "",
                        comment: ""
                    })
                  }else{
                    setSpinner(false);
                    setError('Unable to send Email at this time, Please try again later');
                    setCredentials({
                        name: "",
                        email: "",
                        subject: "",
                        comment: ""
                    })
                  }
                })
                .catch((err) => {
                  console.log(err)
                });
        }
    }

    const onChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };

    return (
        <section className="contact overflow-hidden bg-light" id="contact">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="sec-hd">
                            <span className="heading"></span>
                            <h2 className="sec-title">Contact Us!</h2>
                            <span className="heading"></span>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-8">
                        <div className="contact-box">
                            <div className="custom-form mt-4">
                                <form onSubmit={handleSubmit} name="myForm">
                                    {error === '' ? '' : <div className="alert alert-danger" role="alert">{error}</div>}
                                    {success === '' ? '' : <div className="alert alert-success" role="alert">{success}</div>}
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <input name="name" id="name" type="text" value={credentials.name}
                                                    onChange={onChange}
                                                    className="form-control contact-form" placeholder="Your name" />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <input name="email" id="email" type="email" value={credentials.email}
                                                    onChange={onChange}
                                                    className="form-control contact-form" placeholder="Your email" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <input type="text" name='subject' value={credentials.subject}
                                                    onChange={onChange} className="form-control contact-form" id="subject"
                                                    placeholder="Your Subject.." />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <textarea name="comment" id="comments" value={credentials.comment}
                                                    onChange={onChange} rows="6"
                                                    className="form-control contact-form h-auto"
                                                    placeholder="Your message..."></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6">
                                            <div className="text-center">
                                                <button type="submit" id="submit" name="send"
                                                    className="submitBnt btn btn-rounded bg-gradiant">Send Message {" "}
                                                    {spinner ? (
                                                      <span
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                      ></span>
                                                    ) : (
                                                      ""
                                                    )}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row my-5 py-4 justify-content-center text-center">
                    <div className="col-md-4">
                        <div>
                            <Icon path={mdiGoogleMaps}
                                size={2.5}
                                className='text-primary mdi mdi-google-maps f-50' />
                            <h5 className="mb-1">Location</h5>
                            <p className="f-14 mb-0 text-muted">Lahore, Pakistan</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mt-4 mt-lg-0">
                            <Icon path={mdiEmail}
                                size={2.5}
                                className='text-primary mdi mdi-google-maps f-50' />
                            <h5 className="mb-1">Email</h5>
                            <p className="f-14 mb-0 text-muted">Email: approach@veel.ai</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="mt-4 mt-lg-0">
                            <Icon path={mdiPhone}
                                size={2.5}
                                className='text-primary mdi mdi-google-maps f-50' />
                            <h5 className="mb-1">Phone</h5>
                            <p className="f-14 mb-0 text-muted">0300 - 123VeeL (8335)</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
