import React from 'react'

export default function Mobile() {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="mobile">
                    <a href="#about">
                        <span className="phone">
                            <i className="mdi mdi-cellphone"></i>
                        </span>
                    </a>
                </div>
            </div>
        </div>
    )
}
