import React from 'react'
// import Alert from './Alert';

export default function AboutUs() {

    // const [credentials, setCredentials] = useState({
    //     interest: "",
    //     firstname: "",
    //     lastname: "",
    //     email: "",
    //     phone: "",
    //     type: "",
    // });
    // const [spinner, setSpinner] = useState(false);
    // const [alert, setAlert] = useState(null);

    // const showAlert = (message, type) => {
    //     setAlert({
    //         msg: message,
    //         type: type,
    //     });
    //     setTimeout(() => {
    //         setAlert(null);
    //     }, 5000);
    // };

    // const onChange = (e) => {
    //     setCredentials({ ...credentials, [e.target.name]: e.target.value });
    // };

    // const handleSubmit = (e) => {
    //     setSpinner(true);
    //     e.preventDefault();

    //     const formData = new FormData();
    //     formData.append("firstname", credentials.firstname);
    //     formData.append("lastname", credentials.lastname);
    //     formData.append("email", credentials.email);
    //     formData.append("phone", credentials.phone);
    //     formData.append("interest", credentials.interest);
    //     formData.append("type", credentials.type);

    //     fetch('https://veel.ai/registration/submitformdata.php', {
    //         mode: 'no-cors',
    //         method: "POST",
    //         body: formData,
    //         redirect: 'follow'
    //     })
    //         .then(response => response.json())
    //         .then(response => {
    //             console.log(response);
    //             if (response.status === true) {
    //                 showAlert(response.message, 'success')
    //                 setSpinner(false);
    //                 setCredentials({
    //                     interest: "",
    //                     firstname: "",
    //                     lastname: "",
    //                     email: "",
    //                     phone: "",
    //                     type: "",
    //                 });
    //             } else {
    //                 setSpinner(false);
    //                 showAlert(response.message, 'danger');
    //                 setCredentials({
    //                     interest: "",
    //                     firstname: "",
    //                     lastname: "",
    //                     email: "",
    //                     phone: "",
    //                     type: "",
    //                 });
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //             setSpinner(false)
    //             showAlert('Something went wrong', 'danger');
    //             setCredentials({
    //                 interest: "",
    //                 firstname: "",
    //                 lastname: "",
    //                 email: "",
    //                 phone: "",
    //                 type: "",
    //             });
    //         });
    // };

    return (
        <section className="section contact about bg-light" id="signup">
            <div className="container">
                <div className="row text-center">
                    {/* <div className="col-md-6">
                        <h2>"BE THE FIRST ONE TO KNOW"</h2>
                        <h3>Join Sign-up Waiting List </h3>
                        <div className="contact-box">
                            <div className="custom-form mt-4">
                                <form onSubmit={handleSubmit}>
                                    <p id="error-msg" style={{ opacity: '1' }}> </p>
                                    <div id="simple-msg"></div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <select
                                                    name="interest"
                                                    id="interest"
                                                    className="form-control contact-form"
                                                    value={credentials.interest}
                                                    onChange={onChange}
                                                    required
                                                >
                                                    <option value="" selected disabled>Choose Your Category</option>
                                                    <option value="Become Customer">Be our Customer - A KING</option>
                                                    <option value="Become Partner">Be our Partner - Driver Entrepreneur</option>
                                                    <option value="Become Customer/Partner">Both of Above</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <select
                                                    name="type"
                                                    id="mobility"
                                                    className="form-control contact-form"
                                                    value={credentials.type}
                                                    onChange={onChange}
                                                    required
                                                >
                                                    <option value="" selected disabled>Fleet Type (optional)</option>
                                                    <option value="Bike">Bike</option>
                                                    <option value="Car">Car</option>
                                                    <option value="Car and Bike">Both of Above</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <input
                                                    name="firstname"
                                                    id="firstname"
                                                    type="text"
                                                    className="form-control contact-form"
                                                    placeholder="Your First Name"
                                                    value={credentials.firstname}
                                                    onChange={onChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <input
                                                    name="lastname"
                                                    id="lastname"
                                                    type="text"
                                                    className="form-control contact-form"
                                                    placeholder="Your Last Name"
                                                    value={credentials.lastname}
                                                    onChange={onChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <input
                                                    name="email"
                                                    id="email"
                                                    type="email"
                                                    className="form-control contact-form"
                                                    placeholder="Your Email (optional)"
                                                    value={credentials.email}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <input
                                                    name="phone"
                                                    type="number"
                                                    className="form-control contact-form"
                                                    id="phone"
                                                    placeholder="Your Phone Number"
                                                    value={credentials.phone}
                                                    onChange={onChange}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6 mb-3">
                                            <div className="text-center">
                                                <button
                                                    type="submit"
                                                    id="submit"
                                                    name="send"
                                                    className="submitBnt btn btn-rounded fs-5 bg-gradiant"
                                                >
                                                    Submit {" "}
                                                    {spinner ? (
                                                        <span
                                                            className="spinner-border spinner-border-sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        ></span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                        <div className='col-12 mb-3'>
                                            <Alert alert={alert} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-md-12">
                        <div className="mockup mockup-macbook loaded opened ms-md-5 mt-md-3">
                            <div className="part top">
                                <img src="assets/images/about/macbook-top.svg" alt="" className="img-fluid top" />
                                <img src="assets/images/about/macbook-cover.svg" alt="" className="img-fluid cover" />
                                <video autoPlay muted controls loop>
                                    <source src="assets/images/logoo.mp4" type="video/mp4" />
                                </video>
                            </div>
                            <div className="part bottom">
                                <img src="assets/images/about/macbook-cover.svg" alt="" className="img-fluid cover" />
                                <img src="assets/images/about/macbook-bottom.svg" alt="" className="img-fluid bottom" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
