import React from 'react';
import { HashLink } from 'react-router-hash-link';

export default function NavigationBar() {
    return (
        <nav className="navbar navbar-expand-lg fixed-top navbar-custom sticky" id="navbar">
            <div className="container-fluid">
                <a className="navbar-brand logo text-uppercase" href="/">
                    <img src="assets/images/logo.png" className="logo-dark img-responsive" alt="" height="70" width="150" />
                </a>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
                    aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="mdi mdi-menu text-primary"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <ul className="navbar-nav ms-auto" id="navbar-navlist">
                        <li className="nav-item">
                            <HashLink className="nav-link" to="/#home">Home</HashLink>
                        </li>
                        <li className="nav-item">
                            <HashLink className="nav-link" to="/#features">Features</HashLink>
                        </li>
                        <li className="nav-item">
                            <HashLink className="nav-link" to="/#partners">Partners</HashLink>
                        </li>
                        <li className="nav-item">
                            <HashLink className="nav-link" to="/#services">Our Services</HashLink>
                        </li>
                        <li className="nav-item">
                            <HashLink className="nav-link" to="/#about">About Us</HashLink>
                        </li>
                        <li className="nav-item">
                            <HashLink className="nav-link" to="/#contact">Contact</HashLink>
                        </li>
                    </ul>
                    <div className="ms-auto">
                        <a href="https://wa.me/923001238335" className="btn bg-gradiant">HELP CENTER</a>
                    </div>
                </div>
            </div>
        </nav>
    )
}
