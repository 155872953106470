import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

export default function AppScreenshots() {
    return (
        <section className="section app-slider" id="services">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="sec-hd">
                            <span className="heading"></span>
                            <h2 className="sec-title">Our Signature Services</h2>
                            <span className="heading"></span>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            spaceBetween={30}
                            slidesPerView={3}
                            centeredSlides
                            navigation
                            loop
                            breakpoints={{
                                1920: {
                                    slidesPerView: 3,
                                    spaceBetween: 30
                                },
                                1028: {
                                    slidesPerView: 3,
                                    spaceBetween: 30
                                },
                                480: {
                                    slidesPerView: 1,
                                    spaceBetween: 10
                                },
                                360: {
                                    slidesPerView: 1,
                                    spaceBetween: 10
                                }
                            }}
                        >
                            <SwiperSlide>
                                <div className="app-screenshot-item text-center d-flex justify-content-center">
                                    <div className="screenshot-img mb-4">
                                        <img src="assets/images/slider/one.jpg" style={{borderRadius: '20px'}} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="app-screenshot-item text-center d-flex justify-content-center">
                                    <div className="screenshot-img mb-4">
                                        <img src="assets/images/slider/two.jpg" style={{ borderRadius: '20px' }} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="app-screenshot-item text-center d-flex justify-content-center">
                                    <div className="screenshot-img mb-4">
                                        <img src="assets/images/slider/three.jpg" style={{ borderRadius: '20px' }} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="app-screenshot-item text-center d-flex justify-content-center">
                                    <div className="screenshot-img mb-4">
                                        <img src="assets/images/slider/four.jpg" style={{ borderRadius: '20px' }} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="app-screenshot-item text-center d-flex justify-content-center">
                                    <div className="screenshot-img mb-4">
                                        <img src="assets/images/slider/five.jpg" style={{ borderRadius: '20px' }} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="app-screenshot-item text-center d-flex justify-content-center">
                                    <div className="screenshot-img mb-4">
                                        <img src="assets/images/slider/six.jpg" style={{ borderRadius: '20px' }} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="app-screenshot-item text-center d-flex justify-content-center">
                                    <div className="screenshot-img mb-4">
                                        <img src="assets/images/slider/seven.jpg" style={{ borderRadius: '20px' }} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="app-screenshot-item text-center d-flex justify-content-center">
                                    <div className="screenshot-img mb-4">
                                        <img src="assets/images/slider/eight.jpg" style={{ borderRadius: '20px' }} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="app-screenshot-item text-center d-flex justify-content-center">
                                    <div className="screenshot-img mb-4">
                                        <img src="assets/images/slider/nine.jpg" style={{ borderRadius: '20px' }} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="app-screenshot-item text-center d-flex justify-content-center">
                                    <div className="screenshot-img mb-4">
                                        <img src="assets/images/slider/ten.jpg" style={{ borderRadius: '20px' }} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="app-screenshot-item text-center d-flex justify-content-center">
                                    <div className="screenshot-img mb-4">
                                        <img src="assets/images/slider/eleven.jpg" style={{ borderRadius: '20px' }} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="app-screenshot-item text-center d-flex justify-content-center">
                                    <div className="screenshot-img mb-4">
                                        <img src="assets/images/slider/twelve.jpg" style={{ borderRadius: '20px' }} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="app-screenshot-item text-center d-flex justify-content-center">
                                    <div className="screenshot-img mb-4">
                                        <img src="assets/images/slider/thirteen.jpg" style={{ borderRadius: '20px' }} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="app-screenshot-item text-center d-flex justify-content-center">
                                    <div className="screenshot-img mb-4">
                                        <img src="assets/images/slider/fourteen.jpg" style={{ borderRadius: '20px' }} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="app-screenshot-item text-center d-flex justify-content-center">
                                    <div className="screenshot-img mb-4">
                                        <img src="assets/images/slider/fiveteen.jpg" style={{ borderRadius: '20px' }} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="app-screenshot-item text-center d-flex justify-content-center">
                                    <div className="screenshot-img mb-4">
                                        <img src="assets/images/slider/sixteen.jpg" style={{ borderRadius: '20px' }} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="app-screenshot-item text-center d-flex justify-content-center">
                                    <div className="screenshot-img mb-4">
                                        <img src="assets/images/slider/seventeen.jpg" style={{ borderRadius: '20px' }} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    )
}
