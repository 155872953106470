import React from 'react'

export default function Exclusive() {
    return (
        <section className="section features" id="features">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="sec-hd">
                            <span className="heading"></span>
                            <h2 className="sec-title text-wrap"> Exclusive Top Features & Services <br /> Trust Veel, your all needs will be
                                met</h2>
                            <span className="heading"></span>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-4 col-md-10">
                        {/* <!-- <div data-aos="fade-right" data-aos-duration="1800"> --> */}
                        <div className="features-box text-start">
                            <div className="features-icon float-end ms-2">
                                <img src="assets/images/icons/maintenance.png" width="70%" alt="maintenance" />
                            </div>
                            <div className="pe-3 me-5">
                                <h5 className="f-15 text-uppercase">For Fleet Quality Checks</h5>
                                <p className="text-muted mt-3"> Introducing for the 1st time ever, Veel Flagship Lounge for fleet
                                    Periodic Maintenance, Quality Assurance and to curb the rising CO2 Footprints.</p>
                            </div>
                        </div>

                        <div className="features-box mt-5 text-start">
                            <div className="features-icon float-end ms-2">
                                <img src="assets/images/icons/entrepreneur.png" width="70%" alt="entrepreneur" />
                            </div>
                            <div className="pe-3 me-5">
                                <h5 className="f-15 text-uppercase">For Driver Security Checks </h5>
                                <p className="text-muted mt-3">Introducing for the 1st time ever, Veel Signature Scrutiny Benchmark to shortlist
                                    our Premium Solopreneurs / Ladypreneurs / Transpreneurs for High Quality Service.</p>
                            </div>
                        </div>

                        <div className="features-box mt-5 text-start">
                            <div className="features-icon float-end ms-2">
                                <img src="assets/images/icons/protection.png" width="70%" alt="protection" />
                            </div>
                            <div className="pe-3 me-5">
                                <h5 className="f-15 text-uppercase">For Safety</h5>
                                <p className="text-muted mt-3">1. Dashcam Enabled Fleets <br />
                                    2. First Aid Medical Box
                                    <br />3. Quick Response Force</p>
                            </div>
                        </div>

                        <div className="features-box mt-5 text-start">
                            <div className="features-icon float-end ms-2">
                                <img src="assets/images/icons/healthcare.png" width="70%" alt="healthcare" />
                            </div>
                            <div className="pe-3 me-5">
                                <h5 className="f-15 text-uppercase">For Care</h5>
                                <p className="text-muted mt-3">1. Business Class Fleets <br />
                                    2. Air-purified Cabins <br />
                                    3. Ambulance Backup</p>
                            </div>
                        </div>
                        {/* <!-- </div> --> */}
                    </div>
                    <div className="col-lg-4">
                        <div className="text-center py-4">
                            {/* <!-- <div data-aos="zoom-in" data-aos-duration="1800"> --> */}
                            <img src="assets/images/consumer.png" className="img-fluid" alt="features" />
                            {/* <!-- </div> --> */}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-10">
                        {/* <!-- <div data-aos="fade-left" data-aos-duration="1800"> --> */}
                        <div className="features-box">
                            <div className="features-icon float-start me-2">
                                <img src="assets/images/icons/kid.png" width="70%" alt="kids" />
                            </div>
                            <div className="ps-3 ms-5">
                                <h5 className="f-15 text-uppercase">For Kids</h5>
                                <p className="text-muted mt-3"> Introducing for the 1st time ever, Kids School Mobility with Trusted Drivers and trained Security Guards.</p>
                            </div>
                        </div>

                        <div className="features-box mt-5">
                            <div className="features-icon float-start me-2">
                                <img src="assets/images/icons/female.png" width="70%" alt="female" />
                            </div>
                            <div className="ps-3 ms-5">
                                <h5 className="f-15 text-uppercase">For Females </h5>
                                <p className="text-muted mt-3">For the 1st time ever, Introducing Pink Mobility to comfort mobility for both females as
                                    ladypreneur & lady passengers.</p>
                            </div>
                        </div>

                        <div className="features-box mt-5">
                            <div className="features-icon float-start me-2">
                                <img src="assets/images/icons/airplane.png" width="70%" alt="airplane" />
                            </div>
                            <div className="ps-3 ms-5">
                                <h5 className="f-15 text-uppercase">For Air Mobility </h5>
                                <p className="text-muted mt-3">For the 1st time ever, Introducing Air Mobility, having a Charter Plane to fly high from Earth to Sky.</p>
                            </div>
                        </div>

                        <div className="features-box mt-5">
                            <div className="features-icon float-start me-2">
                                <img src="assets/images/icons/funeral.png" width="70%" alt="funeral" />
                            </div>
                            <div className="ps-3 ms-5">
                                <h5 className="f-15 text-uppercase">For Funeral Mobility </h5>
                                <p className="text-muted mt-3"> For the 1st time ever, Introducing Last Ride towards Final Destination.</p>
                            </div>
                        </div>
                        {/* <!-- </div> --> */}
                    </div>
                </div>
            </div>
        </section>
    )
}
