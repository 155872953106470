import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import QrCode from './pages/QrCode/QrCode';
import Error from './pages/Error/Error';
import PrivacyPolicy from './pages/PrivacyPolicy';
import 'bootstrap/dist/js/bootstrap.bundle';
import TermsConditions from './pages/TermsConditions';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/qrcode" element={<QrCode />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/term-conditions" element={<TermsConditions />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </Router>
  );
}

export default App;
