import React from 'react'

export default function Partners() {
  return (
    <section className="section work bg-light" id="partners">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="sec-hd">
                            <span className="heading"></span>
                            <h2 className="sec-title">PARTNERS</h2>
                            <span className="heading"></span>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="work-pra">
                            <p className="text-muted">For the 1st time ever in Pakistan, introducing the following exclusive benefits for
                                solopreneurs/ ladypreneur / transpreneur with the Partners classification of Tier 01, Tier 02 and Tier 03.</p>
                            <h4 className="my-4">PARTNER BENEFITS</h4>
                            <ul className="inline-item">
                                <li className="list-inline-item">
                                    <i className="uil uil-circle"></i>
                                    <p className="text-muted">02 times a year (every 06 month) chance to win a Umrah Ticket & Visa for partner & his/her single family member as per his/her choice.</p>
                                </li>
                                <li className="list-inline-item">
                                    <i className="uil uil-circle"></i>
                                    <p className="text-muted"> For bikes, accidental Coverage with up-to 14 paydays.</p>
                                </li>
                                <li className="list-inline-item">
                                    <i className="uil uil-circle"></i>
                                    <p className="text-muted">Health Insurance covers with immediate family members.</p>
                                </li>
                                <li className="list-inline-item">
                                    <i className="uil uil-circle"></i>
                                    <p className="text-muted">Rs. 10/litre – Weekly Fuel Discount Voucher.</p>
                                </li>
                                <li className="list-inline-item">
                                    <i className="uil uil-circle"></i>
                                    <p className="text-muted">Free weekly bike/Car body wash & Free Monthly Full Car/bike wash service.</p>
                                </li>
                                <li className="list-inline-item">
                                    <i className="uil uil-circle"></i>
                                    <p className="text-muted">Free Mobil Oil/Filter Change & Free Cabin Perfume.</p>
                                </li>
                                <li className="list-inline-item">
                                    <i className="uil uil-circle"></i>
                                    <p className="text-muted">Free Monthly Fleet Periodic Maintenance Check-up.</p>
                                </li>
                                <li className="list-inline-item">
                                    <i className="uil uil-circle"></i>
                                    <p className="text-muted">01 time in a Quarter Family Dinner Voucher.</p>
                                </li>
                                <li className="list-inline-item">
                                    <i className="uil uil-circle"></i>
                                    <p className="text-muted">01 time a year Bonus “Eidi” for your family (50% of your average annual income) on
                                        Eid-ul-fitre.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        {/* <!-- <div className="section-content" data-aos="fade-right" data-aos-duration="1800"> --> */}
                            <div className="testimonial-contaent">
                                <div className="testimonial-right">
                                    <div className="testimonial-circle">
                                        <div className="media">
                                            <img src="assets/images/logo.png" className="img-fluid pt-5 mt-4" alt="logo"/>
                                        </div>
                                        <span className="ring1 animate-v2"><img src="assets/images/icons/fuel.png"
                                                className="img-fluid" alt="fuel" /></span>
                                        <span className="ring2 animate-v3"><img src="assets/images/icons/accident.png"
                                                className="img-fluid" alt="accident" /></span>
                                        <span className="ring3 animate-v2"><img src="assets/images/icons/ambulance.png"
                                                className="img-fluid" alt="ambulance" /></span>
                                        <span className="ring4 animate-v3"><img src="assets/images/icons/car-wash.png"
                                                className="img-fluid" alt="car-wash" /></span>
                                        <span className="ring5 animate-v2"><img src="assets/images/icons/ramadan.png"
                                                className="img-fluid" alt="ramadan" /></span>
                                        <span className="ring6 animate-v3"></span>
                                        <span className="ring7 animate-v2"></span>
                                        <span className="ring8 animate-v3"></span>
                                    </div>
                                </div>
                            </div>
                        {/* <!-- </div> --> */}
                    </div>
                </div>
            </div>
        </section>
  )
}
