import React, { useEffect } from 'react'
import Copyrights from '../components/Copyrights'
import NavigationBar from '../components/NavigationBar'

export default function PrivacyPolicy() {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'Privacy Policy | Veel Mobility';
    });

    return (
        <>
            <NavigationBar />
            <div className='container mt-5 pt-5'>
                <div className="d-flex justify-content-center">
                    <img
                        className="pt-5"
                        src="./assets/images/logo.png"
                        alt="logo"
                        width={150}
                    />
                </div>
                <div className='row mt-3'>
                    <div className='col-12'>
                        <h1 className='heading text-center'>Privacy Policy</h1>
                        <h2 className='heading'>1. Introduction</h2>
                        <p>When you use Veel Mobility, you trust us with your personal data. Veel Brand is committed to keeping that trust. That starts with helping you understand our privacy practices. This Brand Focuses on providing a Service & Product that meets nearly all customers’ daily needs through our AI powered app and website.This notice describes the personal data (“data”) we collect, how it’s used and shared, and your choices regarding this data. We recommend that you read this along with our privacy overview, which highlights key points about our privacy practices</p>
                        <h2 className='heading'>2. Overview</h2>
                        <h3 className='heading'>Scope</h3>
                        <p>This notice applies to users of Veel’s services anywhere in the world, including users of Veel’s apps, websites, features, or other services. This notice describes how Veel and its affiliates collect and use data. This notice applies to all Veel users globally, unless they use a service covered by a separate privacy notice.</p>
                        <p>This notice specifically applies to:</p>
                        <h3 className='heading'>User</h3>
                        <p>All those subject to this notice are referred to as “users” in this notice</p>
                        <ul>
                            <li><strong className='heading'>Customer: </strong>Individuals who request or receive transportation and related services via their veel account.</li>
                            <li><strong className='heading'>Drivers: </strong>Individuals who provide transportation to Riders individually or through partner transportation companies.Our Premium Solopreneurs/Ladypreneurs/Transpreneurs for High Quality Service.</li>
                            <li><strong className='heading'>Order Recipients: </strong>Individuals who request or receive food or other products and services for delivery or pick-up via their Veel Express Delivery. This includes individuals who use guest checkout features to access delivery or pick-up services without creating and/or logging into their account.</li>
                            <li><strong className='heading'>Delivery Persons: </strong>Individuals who provide delivery services via Veel Express Delivery.</li>
                            <li><strong className='heading'>Guest Users: </strong>Individuals who receive mobility and delivery services ordered by other Veel account owners, including those who receive services arranged by Veel for Business customers (collectively, “Enterprise Customers”), or by friends, family members or other individual account owners.</li>
                        </ul>
                        <h3 className='heading'>Types of Services</h3>
                        <p>This Brand is offering following Exclusive services</p>
                        <ul>
                            <li><strong className='heading'>Quick Mobility: </strong>To facilitate instant traveling, we are providing Quick Mobility services.</li>
                            <li><strong className='heading'>Schedule Mobility: </strong>Travelers can set schedules for their mobilities to ease their punctuality.</li>
                            <li><strong className='heading'>Rental Mobility: </strong>Consumers can access our Rental Services for limited locations.</li>
                            <li><strong className='heading'>Pink Mobility: </strong>For the 1st time ever, Introducing Pink Mobility to comfort mobility for both females as Lady-preneur & Lady passengers.</li>
                            <li><strong className='heading'>Express Delivery: </strong>For the 1st time ever, Introducing Paddle delivery for Eco-friendly  and short distance deliveries.The Veel app allows users to request transportation of products in addition to people and food. We are also giving options for deliveries on motorcycles.</li>
                            <li><strong className='heading'>Kids Happy Mobility: </strong>For the 1st time ever, We are introducing Kids Happy Mobility to make their Birthday Extra Special and Kids Smart School Coach.</li>
                            <li><strong className='heading'>Free Ambulance Services: </strong>We are Introducing location based Ambulance Mobility for free. As a Corporate Social Responsibility, Veel ambulance services will be provided free of cost on humanitarian grounds for all kinds of emergency services like first aid or rushing to hospital in limited locations.</li>
                            <li><strong className='heading'>In-app Calling: </strong>Users and drivers can contact for confirmation purposes.</li>
                            <li><strong className='heading'>In-app Messaging: </strong>Consumers can contact their driver.</li>
                            <li><strong className='heading'>Chatbot Support via WhatsApp Business Account: </strong>Consumers can directly access our Customer support services via this feature.</li>
                            <li><strong className='heading'>Audio Location Searching: </strong>Consumers can add location manually and also by voice search location, by using Meena by google.</li>
                            <li><strong className='heading'>Selfie Rating: </strong>Consumers can give ratings by selfie, the app will only capture the face expression to read the feedback only.</li>
                            <li><strong className='heading'>QR Tag Operation: </strong>To avoid any mishap and scam, we are introducing QR tag operation to make our mobility Safest & Premium.</li>
                            <li><strong className='heading'>DashCam: </strong>For security purposes, we are introducing DashCam into our limited transports for monitoring, training and development purposes only.</li>
                        </ul>
                        <h3 className='heading'>Future Mobilities</h3>
                        <p>Veel will provide</p>
                        <ul>
                            <li><strong className='heading'>Handicap Mobility: </strong>We are providing Veel Signature Handicap Mobility for free.</li>
                            <li><strong className='heading'>Air Mobility: </strong>For the 1st time ever, we are introducing Air Mobility for our consumers.</li>
                            <li><strong className='heading'>C2C Mobility: </strong>To facilitate our customers from city to city traveling.</li>
                        </ul>
                        <h2 className='heading'>3. Data Collection</h2>
                        <p>This notice also governs Veel’s other collections of data in connection with its services.</p>
                        <p>For example, we may collect the contact information of owners or employees of restaurants or other merchants on the Veel Express Delivery; the contact information of individuals that manage and use accounts owned by Enterprise Customers; or data of individuals who start but do not complete their applications to be entrepreneurs or delivery persons.</p>
                        <p>Our privacy practices are subject to applicable laws in the places in which we operate. This means that we engage in the practices of a particular country or region only if permitted under the laws of those places.</p>
                        <p>Veel operates, and processes personal data, globally; wherever and whenever you travel, your data travels with you so we can offer you a seamless experience. We also respect your data protection rights whether you are in your home country or elsewhere.</p>
                        <p>This means we may transfer personal data to countries other than your home country in order to provide our services in a different country at your request. We may also transfer personal data to another country if that is necessary to respond to a customer support request that you submitted outside your region. And trip/order histories may be accessed by users in another country after they have left the country where services were rendered. Because our services involve multiple users at the same time, limited personal data may be accessible to the other user in another country.</p>
                        <p>We do so in order to fulfill our agreements with all of our users, or based on users’ instructions or prior consent, adequacy decisions for the relevant countries, or other transfer mechanisms as may be available under applicable law, such as the Standard Contractual Clauses.</p>
                        <p>We may also transfer data to other countries in order to respond to a request for personal data from a law enforcement agency. To provide additional safeguards to these data transfers, Veel applies a robust internal procedure for processing these requests. Veel only discloses personal data to law enforcement agencies in a manner that is consistent with the regulatory requirements of applicable laws.</p>
                        <p>To help provide equivalent data protection wherever you use our services, Veel has implemented the following measures across the globe:</p>
                        <ul>
                            <li>Policies and procedures to limit access to, and the processing of personal data for defined purposes.</li>
                            <li>Specific training of personnel responsible for managing personal data.</li>
                            <li>A government data access policy that refuses government access to data, except when disclosure is mandated by applicable laws according to the country where the company is operating.</li>
                            <li>Robust protection of data while it is in transit between Veel apps and our servers, and when the data is processed on our servers. This includes encryption and the use of pseudonymized data to help protect users' full identity against unauthorized disclosure.</li>
                        </ul>
                        <p>For your convenience, the exercise of your data protection rights can be directed to Veel via any of the designated channels mentioned above, and will be handled centrally on behalf of the relevant controller. Questions, comments, and complaints about Veel’s data practices can be submitted here. You may also use this form to submit a question to veel’s Data Protection Officer and to ask us for a copy of the Standard Contractual Clauses or other transfer mechanisms in place for the data processing activity of your inquiry.</p>
                        <h2 className='heading'>4. Data collections and uses</h2>
                        <h3 className='heading ms-2'>A. The data we collect:</h3>
                        <ul>
                            <li>Provided by users to Veel, such as during account creation.</li>
                            <li>Created during use of our services, such as location, app usage, and device data.</li>
                            <li>From other sources, such as other users or account owners, business partners, vendors, insurance and financial solution providers, and governmental authorities.</li>
                        </ul>
                        <h3 className='heading ms-2'>B. Veel's Privacy Principles:</h3>
                        <p className='ms-4'>When you use Veel’s services, you trust us with your personal data. We’re committed to keeping that trust, and that starts with helping you understand our privacy practices. Our Privacy Principles set the foundation for how we approach privacy at Veel.</p>
                        <h3 className='heading ms-2'>C. We do the right thing with data:</h3>
                        <p className='ms-4'>Responsible data management is a prerequisite for continuous innovation. We maintain the value of personal data for Veel and our users by handling data as users expect, keeping it accurate and complete, and properly destroying it when it is no longer needed. This improves our products, earns and keeps our users’ trust, and differentiates us in the market.</p>
                        <h3 className='heading ms-2'>D. We build privacy into our products from start to finish:</h3>
                        <p className='ms-4'>Privacy is an important component for building world-class products and services, from inception to rollout and beyond. Performing privacy reviews on new and changed products, technologies, and services makes sure they fulfill users’ expectations and forms the bedrock of an exceptional customer experience.</p>
                        <h3 className='heading ms-2'>E. We collect only what we need:</h3>
                        <p className='ms-4'>We have a specific objective in mind when collecting, using, or handling personal data that is consistent with our objectives and values. We collect and use only the amount of personal data we need for approved and lawful purposes.</p>
                        <h3 className='heading ms-2'>F. We are transparent about our data practices:</h3>
                        <p className='ms-4'>We are forthright about the personal data we collect and how we’re using and sharing it. We do what we say.</p>
                        <h3 className='heading ms-2'>G. We give users choices about their data:</h3>
                        <p className='ms-4'>We give users clear choices about their privacy and controls that are easy to use so that they can manage their data.</p>
                        <h3 className='heading ms-2'>H. We safeguard personal data:</h3>
                        <p className='ms-4'>We provide reasonable and appropriate safeguards to prevent loss, and unauthorized use or disclosure, of personal data.</p>
                        <h2 className='heading'>5. How we use your information:</h2>
                        <p>Veel puts transportation, food delivery, and other services at your fingertips. Understanding what data we collect and how we use it should be just as easy.</p>
                        <p>We’ve also summarized this information in the charts below. These show for each category of Veel users (Consumer, Order Recipients, and Driver /Delivery Persons) the purposes for which Veel uses data, and the types of data we use for each purpose.</p>
                        <ul>
                            <li><h3 className='heading'>User Profile</h3></li>
                            <p>Includes name, email, phone number, login name and password, address, profile picture, payment or banking information (including related payment verification information), Entrepreneur’s license, Customer Satisfaction rating of other players and other government issued documents (which may include identification numbers as well as birth date, gender, and photo), vehicle or and delivery persons, emergency contact information, user settings, and evidence of perfect medical condition like eyesight & health or fitness to provide services using Veel apps.</p>
                            <li><h3 className='heading'>Background check information (Entrepreneur and delivery persons):</h3></li>
                            <p>Includes information submitted during the entrepreneur/delivery person application via physical verification process, such as entrepreneur history or criminal record (where permitted by law), license status, known aliases and prior addresses, and right to work.</p>
                            <li><h3 className='heading'>Identity Verification Photos</h3></li>
                            <p>Includes photos of users (such as, selfies) and/or their government issued identifications (such as, Entrepreneur’s license or passports).</p>
                            <li><h3 className='heading'>User Content</h3></li>
                            <p>Includes ratings or feedback for other users; photographs, audio or video recordings submitted by users in connection with a customer support request.</p>
                            <li><h3 className='heading'>Travel Itinerary Information</h3></li>
                            <p>Includes times and dates of upcoming flight, lodging or car rental reservations, through manual input or authorized access to the Gmail travel-related email confirmations.</p>
                            <li><h3 className='heading'>Demographics</h3></li>
                            <p>Includes the characteristics provided by users when required for certain services or programs (such as birth date/age, gender or occupation), through voluntary surveys, or inferred from other data collected from users.</p>
                            <li><h3 className='heading'>Location Data</h3></li>
                            <p>Includes precise or approximate location data collected from users’ devices.</p>
                            <li><h3 className='heading'>Transaction Data</h3></li>
                            <p>Includes the type of services requested or provided, trip or order details (such as date and time, pick-up and drop off addresses, distance traveled and items ordered); and payment transaction information (such as a restaurant’s or merchant's name and location, amount charged, and payment method).</p>
                            <li><h3 className='heading'>Usage Data</h3></li>
                            <p>Includes access dates and times, app features or pages viewed, browser type, and app crashes and other system activity</p>
                            <li><h3 className='heading'>Device Data</h3></li>
                            <p>Includes the hardware models, device IP address or other unique device identifiers, operating systems and versions, software, preferred languages, advertising identifiers, device motion data, and mobile network data.</p>
                            <li><h3 className='heading'>Communication Data</h3></li>
                            <p>Includes the time and date of calls, texts and in-app messages & in-app calls between users enabled through Veel's apps and the content of text and in-app messages.</p>
                            <li><h3 className='heading'>Data from Other Sources</h3></li>
                            <p>Includes from or in connection with:</p>
                            <li style={{ listStyleType: 'none' }}>
                                <ul>
                                    <li>Referrals</li>
                                    <li>OBO (on behalf of) requests</li>
                                    <li>Claims or disputes</li>
                                    <li>Veel business partners through which users create or access their Veel accounts</li>
                                    <li>Vendors who help verify users</li>
                                    <li>Insurance, vehicle or financial service providers</li>
                                    <li>Partner transport companies</li>
                                    <li>Publicly available sources</li>
                                    <li>Marketing service providers and/or data resellers</li>
                                    <li>Governments, regulatory authorities or law enforcement officials.</li>
                                </ul>
                            </li>
                        </ul>
                        <h3 className='heading'>Important Note</h3>
                        <p>Veel does not sell or share your personal data to third parties for their direct marketing, except with your consent. Some of Veel’s products, services, and features involve sharing information with other users. For example, when you request mobility, the location of your pickup and dropoff points are shared with the driver. Veel helps you get where you want to go. This means that location information is essential to our services. We use it to help riders and drivers find each other, determine the best routes, enable users to track their progress and share their trip status with friends, develop new features and services, enhance safety and security.</p>
                        <h2 className='heading'>Veel collects the following data: </h2>
                        <h3 className='heading'>Data provided by users.</h3>
                        <p>This includes: </p>
                        <ul>
                            <li><h3 className='heading'>Consumer profile information:</h3></li>
                            <p>We collect data when users create or update their Veel accounts, or place orders via guest checkout features. This may include their name, email, phone number, login name and password, address, profile picture, payment or banking information (including related payment verification information), driver’s license and other government issued documents (which may include identification numbers as well as birth date, gender, and photo). This also includes vehicle or insurance information of drivers entrepreneur and delivery persons, emergency contact information, user settings, and evidence of health or fitness to provide services using Veel apps </p>
                            <li><h3 className='heading'>Background check information (Drivers and Delivery persons):</h3></li>
                            <p>This includes information submitted during the driver/delivery person application process, such as driver entrepreneur history or criminal record (where permitted by law), license status, known aliases, prior addresses, and right to work. This information may be collected by an authorized vendor on Veel’s behalf.</p>
                            <li><h3 className='heading'>Identity verification photos:</h3></li>
                            <p>This includes photos of users (such as, selfies) and/or their government issued identifications (such as, driver’s license or passports). Such photos may be used to verify a user’s identity, such as through facial verification technologies. </p>
                            <li><h3 className='heading'>Demographic data: </h3></li>
                            <p>We may collect demographic data about users, such as birth date/age, gender or occupation, when required for certain Veel services or programs, such as Veel Cash,  deliveries or safety features that allow women users to set a preference to provide or receive services to/from other women (“Pink Mobility ”). We may also infer demographic data from other data collected from users. For example, we have integrated AI feature gender enabled lens to make the Pink Mobility Services limited to specific gender for the purpose of safety and comfort. such as Pink Mobility, or to display personalized ads for third party products or services. We may also collect demographic data through user surveys. </p>
                            <li><h3 className='heading'>Consumer content:</h3></li>
                            <p>We collect the data submitted by users when they contact Veel customer support (including at Veel Window or via phone chat or video conferencing tools), provide ratings or feedback for other users, restaurants or merchants, or otherwise contact Veel. This may include photographs, or audio or video recordings submitted by users in connection with a customer support request. This also includes metadata relating to the method you use to communicate with Veel. The contents of photos and recordings submitted by users are subject to. <br /> In certain jurisdictions, users can record the audio and/or video of their trips through an in-app feature or using a dashcam. In-app recordings are encrypted and stored on consumer’s devices, and are only shared with Veel if submitted by the users in connection with safety incidents. Please see here for more information. </p>
                            <li><h3 className='heading'>Mobility  information:</h3></li>
                            <p>We collect travel itinerary information, including times and dates of upcoming flight, lodging or car rental reservations, from users of our Veel Travel feature. We collect such information:</p>
                            <p>(i) when users manually input their information into their Veel Mobility  itinerary; or</p>
                            <p>(ii) if authorized by users to access their Gmail accounts, from travel-related email confirmations.</p>
                            <p>If so authorized, Veel will only access consumer’ Gmail accounts to collect mobility itinerary information to enable the  Mobility  feature, and will adhere to Google’s API Services Consumer Data Policy, including the limitations on use of data collected from user’s Gmail accounts.</p>
                        </ul>
                        <h3 className='heading'>Data created during use of our services.</h3>
                        <p>This includes: </p>
                        <ul>
                            <li><h3 className='heading'>Location data <span className='text-white fs-4'>(Driver and Delivery person):</span></h3></li>
                            <p>We collect precise or approximate location data from driver and delivery person mobile devices when the Veel app is running in the foreground (app open and on-screen) or background (app open but not on-screen).</p>
                            <li><h3 className='heading'>Location data <span className='text-white fs-4'>(traveler and order recipients):</span></h3></li>
                            <p>We collect precise or approximate location information from riders, and order recipients mobile devices if they enable us to do so via their device settings.</p>
                            <p>Veel collects such data from the time a mobility or order is requested until it is finished, and any time the app is running in the foreground (app open and on-screen). See “Choice and transparency” below for information on how travel and order recipients can enable precise location data collection. Riders and order recipients may use the Veel apps without enabling collection of location data from their mobile devices. However, this may affect certain features in the Veel app. For example, a traveler who has not enabled precise location data will have to manually enter their pickup address.</p>
                            <p>In addition, precise location data collected from a driver's device during a trip is linked to the traveler’s account, even if the rider has not enabled precise location data to be collected from their device. This data is used for purposes such as receipt generation, customer support, fraud detection, insurance, and litigation.</p>
                            <li><h3 className='heading'>Transaction information:</h3></li>
                            <p>We collect transaction information related to the use of our services, including the type of services requested or provided; trip or order details (such as date and time, requested pick-up and drop off addresses, distance traveled and items ordered); and payment transaction information (such as a restaurant’s or merchant's name and location, amount charged, and payment method). We also associate a user’s name with that of anyone who uses their promotion code.</p>
                            <li><h3 className='heading'>Usage data:</h3></li>
                            <p>We collect data about how consumers interact with our services. This includes access date and time, app features or pages viewed, browser type, and app crashes and other system activity.</p>
                            <li><h3 className='heading'>Device data:</h3></li>
                            <p>We collect data about the devices used to access our services, including the hardware models, device IP address or other unique device identifiers, operating systems and versions, software, preferred languages, advertising identifiers, device motion data, and mobile network data.</p>
                            <li><h3 className='heading'>Communications data:</h3></li>
                            <p>We collect data regarding phone, text or in-app communications between users that are enabled through Veel’s app. This includes date and time, as well as the content of text or in-app messages. We may also collect the content of phone calls solely where users are notified in advance that the call may be recorded.</p>
                        </ul>
                        <h3 className='heading'>Data Collection policies from other sources</h3>
                        <h4 className='heading'>A. Data from other sources includes:</h4>
                        <ul>
                            <li>Consumers participating in our referral programs. For example, when a user refers to another person, we receive the referred person&rsquo;s data from that user.</li>
                            <li>Veel account owners who request services for or on behalf of other consumers (such as friends or family members), or who enable other consumers to request or receive services through their business accounts</li>
                            <li>Consumers or others providing information in connection with claims or disputes.</li>
                            <li>Veel business partners through which consumers create or access their Veel account, such as payment providers, social media services, or apps or websites that use Veel&rsquo;s APIs or whose APIs Veel uses. </li>
                            <li>Veel business partners in connection with debit or credit cards issued by a financial institution in partnership with Veel to the extent disclosed in the terms and conditions for the card.</li>
                            <li>Vendors who help us verify consumers&apos; identity, background information, and eligibility to work, or who screen consumers in connection with sanctions, anti-money laundering, or know-your-customer requirements.</li>
                            <li>Insurance, vehicle, or financial services providers for drivers and/or delivery persons.</li>
                            <li>Partner transportation companies (for drivers or delivery persons who use our services through an account associated with such a company).</li>
                            <li>Publicly available sources.</li>
                            <li>Marketing service providers or data resellers whose data Veel uses for marketing or research.</li>
                            <li>Law enforcement officials, public health officials, and other government authorities.</li>
                        </ul>
                        <h4 className='heading'>B. How we use personal data</h4>
                        <p>Veel uses data to enable reliable and convenient transportation, delivery, and other products and services. We also use such data:</p>
                        <ul>
                            <li>To enhance the safety and security of our users and services</li>
                            <li>For customer support</li>
                            <li>For research and development</li>
                            <li>To enable communications between users</li>
                            <li>For marketing and advertising</li>
                            <li>To send non-marketing communications to users</li>
                            <li>In connection with legal proceedings</li>
                        </ul>
                        <h3 className='heading'>We use the data we collect: </h3>
                        <h4 className='heading'>1. To provide our services.</h4>
                        <p>Veel uses data to provide, personalize, maintain, and improve our services. This includes using data to: </p>
                        <ul>
                            <li>Create/update accounts.</li>
                            <li>Enable transportation, delivery, and other services/features, such as: </li>
                            <li>Using location data to navigate rider pick-ups and order drop-offs, calculate ETAs, and track the progress of mobility or deliveries.</li>
                            <li>Features that involve data sharing, such as sharing driver first name and vehicle information with travelers to facilitate pick-ups, user profile and trip details for ETA sharing and fare splitting, and user ratings and compliments.</li>
                            <li>Match available driver entrepreneurs and delivery persons to users requesting services. Consumers can be matched based on availability, location/proximity, user settings/preferences, and other factors such as the likelihood to accept a trip based on their past behavior or preferences. Please see here for further information about our matching process. </li>
                            <li>Features that facilitate the use of our services by those with disabilities. </li>
                            <li>Features that involve account linking, such as linking email to create Veel mobility itineraries and linking rewards programs to enable partnership benefits.</li>
                            <li>Enable dynamic pricing, in which the price of a traveler, or the delivery fee for Veel Express Delivery orders, is determined based on factors such as estimated time and distance, predicted route, estimated traffic, and the current number of users requesting or providing services.</li>
                            <li>Process payments. </li>
                            <li>Personalize Customers accounts. For example, we may present a Veel Food customer with personalized restaurant or food recommendations based on their prior orders. </li>
                            <li>Facilitate insurance, vehicle, invoicing, or financing solutions. </li>
                            <li>Provide customers with a trip or delivery updates, generate receipts, and inform them of changes to our terms, services, or policies. </li>
                            <li>Perform necessary operations to maintain our services, including troubleshooting software bugs and operational problems. Veel performs the above activities on the grounds that they are necessary to fulfill the terms of our agreements with consumers, are compatible with such uses, or on the grounds that they are necessary for purposes of Veel&rsquo;s and its consumers&rsquo; legitimate interests.</li>
                        </ul>
                        <h4 className='heading'>2. Safety and security.</h4>
                        <ul>
                            <li>We use data to help maintain the safety, security, and integrity of our services and consumers. This includes: </li>
                            <li>Verifying consumers&apos; identity and eligibility to provide transportation or deliveries, including through reviews of background checks, to help prevent use of our services by unsafe driver entrepreneurs and/or travelers. </li>
                            <li>This includes Veel&apos;s Real-Time ID Check function in some areas, which asks driver entrepreneurs and delivery personnel to upload a selfie before connecting to the internet to assist verify that the driver entrepreneurs or delivery person using the app matches the Veel account we have on record. We might also utilize this capability to verify other things, including when changing a bank account&apos;s information or getting account access back.</li>
                            <li>In addition, we may use facial recognition technology to process consumer profile photographs, identification photographs, or other user-submitted photographs to prevent identity-borrowing or use of our services by unauthorized drivers or delivery people. </li>
                            <li>We may also use selfies to verify that consumers are wearing masks, helmets, or other safety gear through the use of object verification technology. In some countries, travelers may be required to verify their identity to use cash and certain other payment methods. </li>
                            <li>In such cases, Veel allows travelers to provide their ID number and/or a photo of their identification card to complete such verifications. Please see here for more information about traveler identity verification. </li>
                            <li>Similarly, order recipients may be required to provide their age and an image of their government-issued identification to verify their eligibility to an order receiving.</li>
                            <li>Using data from the driver or delivery person devices to detect unsafe driving behavior such as speeding or harsh braking and acceleration, and to inform them of safer driving practices. We also use data from delivery person&apos;s devices to verify the type of vehicles they used to provide deliveries. </li>
                            <li>Using driver location information, and communications between travelers and drivers, to identify cancellation fees earned or induced through fraud. For example, if we determine that a driver is delaying a traveler&apos;s pickup in order to induce a cancellation, we will not charge the traveler a cancellation fee and will adjust the amounts paid to the driver to omit such a fee. To object to such an adjustment, please contact Veel customer support. </li>
                            <li>Using the device, location, consumer profile, usage, and other data to prevent, detect and combat other types of fraud. This includes identifying fraudulent accounts or uses of our services, preventing the use of our services by unauthorized driver entrepreneurs or delivery persons, verifying consumer identities in connection with certain payment methods, and preventing and combating unauthorized access to consumer accounts. In some cases, such as when a consumer is determined to be abusing Veel&rsquo;s referral program or has submitted fraudulent documents, such behavior may result in automatic deactivation, or according to Pakistani Law, or where otherwise required by law, deactivation after human review. To object to such a deactivation, please contact Veel customer support. </li>
                            <li>Using user ratings, reported incidents, and other feedback to encourage compliance with our Community Guidelines and as grounds for deactivating consumers with low ratings or who otherwise violated such guidelines in certain countries. For more information about how ratings are determined and used, please see here for rider ratings, here for driver ratings, and here for delivery person ratings. To object to such a deactivation, please contact Veel customer support. </li>
                            <li>Using driver data (such as location, ratings, and gender where legally permitted) and traveler data (such as ratings, origin, and destination) to predict and help avoid pairings of consumers that may result in an increased risk of conflict (for instance, because one consumer previously gave the other a rating of 5 impressions). </li>
                            <li>Sharing consumer name, location, phone number, vehicle details, and other relevant information with third-party companies that support the management and de-escalation of interpersonal conflicts that may occur between consumers while on a trip or in the process of making a delivery. </li>
                            <li>Veel performs the above activities on the grounds that they are necessary to fulfill the terms of our agreements with consumers, and/or for purposes of the legitimate safety and security interests of Veel, our consumers, and members of the general public. Veel performs the above activities on the grounds that they are necessary to fulfill the terms of our agreements with consumers, and/or for purposes of the legitimate safety and security interests of Veel, our consumers and members of the general public.</li>
                        </ul>
                        <h4 className='heading'>3. Customer support:</h4>
                        <ul>
                            <li>Veel uses the information we collect (which may include call recordings) to provide customer support, including to investigate and address consumer concerns and to monitor and improve our customer support responses and processes.</li>
                            <li>Veel performs the above activities on the grounds that they are necessary to fulfill the terms of our agreements with consumers or for purposes of Veel’s legitimate interest in monitoring and improving its customer support services</li>
                        </ul>
                        <h4 className='heading'>4. Research and development:</h4>
                        <ul>
                            <li>We use data for testing, research, analysis, product development, and machine learning to improve the consumer experience. This helps us make our services more convenient and easy-to-use, enhance the safety and security of our services, and develop new services and features.</li>
                            <li>Veel performs the above activities on the grounds that they are necessary for purposes of Veel’s legitimate interests in improving and developing new services and features.</li>
                        </ul>
                        <h4 className='heading'>5. Enabling communication between consumers:</h4>
                        <ul>
                            <li>For instance, a driver might text or call a passenger to confirm a pick-up location via in-app calling service which is free for 30s , a passenger might call a driver via in-app calling by using Green Button (Veel Consumer Calling) which is also free for 30s, or a delivery person might get in touch with an order recipient to provide updates.</li>
                            <li>Veel performs the above activities on the grounds that they are necessary to fulfill the terms of our agreements with consumers. </li>
                        </ul>
                        <h4 className='heading'>6. Marketing and Advertising: </h4>
                        <p>Veel uses data (other than Guest Consumer data) to market its services, and those of  Veel partners. </p>
                        <p>This may include:</p>
                        <ul>
                            <li>Sending emails, text messages, push notification, in-app messages, in-app calling or other communications marketing or advertising Uber products, services, features, offers, promotions, sweepstakes, news and events. </li>
                            <li>This may include using location, trip and order history, and usage data to send marketing communications that are personalized based on consumers' observed or inferred interests and characteristics. For example, we may send push notifications suggesting a consumer’s favorite destinations or merchants, or in-app messages and in-app calling  offering discounts or promo for products or merchants similar to those a consumer has previously ordered.</li>
                            <li>Displaying Veel advertising on third party apps or websites. This includes using location, trip and order history, and usage data, and sharing hashed email addresses and device or consumer identifiers with advertising partners (such as Facebook and TikTok), to personalize such ads to consumers’ interest.</li>
                            <li>Displaying third party advertising in Veel’s app or in connection with our services. </li>
                            <br />
                            <p>This includes ads for restaurants or merchants that are available on Veel Express Delivery. These ads (which are identified as “Sponsored Listings” in Veel’s app) include recommendations that are personalized based on users’ location and order histories.</p>
                            <p>This also includes ads for other third party products or services that are personalized based on users’ observed or inferred interests and characteristics, which we determine using data such as approximate location, trip information and history, usage data, demographic data (which may include inferred gender), and device or user identifiers. This also includes ads that are personalized based on data about the current trip or delivery request, including time of request and services requested. For example, if a user requests a trip to a supermarket, we may display in-app ads for third party products that may be available at that supermarket.</p>
                            <li>Measuring the effectiveness of Veel’s ads, and of third party ads displayed in Veel’s app or in connection with our services. Veel performs the above activities on the grounds that they are necessary for purposes of Veel’s legitimate interests in informing users about Veel services and features or those offered by Veels’ partners. See the sections titled “Choice and transparency” and “Marketing and advertising choices” for information on your choices regarding how Veel may use your data for marketing and advertising.</li>
                        </ul>
                        <h4 className='heading'>7. Non-marketing communications:</h4>
                        <p>Veel may use data to send surveys and other communications that are not for the purpose of marketing the services or products of Veel or its partners. We may also send consumer communications regarding elections, ballots, referendums, and other political processes that relate to our services. For example, Veel has notified some consumers by email of ballot measures or pending legislation relating to Veel’s services in those consumers’ areas.</p>
                        <p>Veel performs the above activities on the grounds that they are necessary to fulfill the terms of our agreements with consumers, or for purposes of Veel’s and its consumers’ legitimate interests in informing consumers about events that may have an impact on their use of Veel services.</p>
                        <h4 className='heading'>8. Legal proceedings and requirements: </h4>
                        <p>We use data to investigate or address claims or disputes relating to use of Veel’s services, to satisfy requirements under applicable laws, regulations, or operating licenses or agreements, or pursuant to legal process or governmental request, including from law enforcement.</p>
                        <p> Veel performs the above activities on the grounds that they are necessary for purposes of Veel’s legitimate interests in investigating and responding to claims and disputes relating to use of Veel’s services and features, and/or necessary for compliance with applicable legal requirements.</p>
                        <h2 className='heading'>Cookies and third-party technologies</h2>
                        <p>Veel and its partners use cookies and other identification technologies on our app, website, emails, and online ads for purposes described in this notice, and Veel’s Cookie Notice. Cookies are small text files that are stored on browsers or devices by websites, apps, online media, and advertisements. </p>
                        <p>Veel uses cookies and similar technologies for purposes such as:</p>
                        <ul>
                            <li>Authenticating consumers</li>
                            <li>Remembering consumer preferences and settings</li>
                            <li>Determining the popularity of content</li>
                            <li>Delivering and measuring the effectiveness of advertising campaigns</li>
                            <li>Analyzing site traffic and trends, and generally understanding the online behaviors and interests of people who interact with our services We may also allow others to provide audience measurement and analytics services for us, to serve advertisements on our behalf across the internet, and to track and report on the performance of those advertisements. These entities may use cookies, SDKs and other technologies to identify the devices used by visitors to our website, as well as when they visit other online sites and services.</li>
                        </ul>
                        <h2 className='heading'>Data sharing and disclosure</h2>
                        <p>Some of Veel’s services and features require that we share data with other consumers or at a consumer’s request. We may also share such data with our affiliates, subsidiaries, and partners, for legal reasons or in connection with claims or disputes. </p>
                        <h3 className='heading'>Veel may share data:</h3>
                        <h4 className='heading'>1. With other users</h4>
                        <p>This includes sharing:</p>
                        <ul>
                            <li>Travelers ’ first name, rating, and pickup and/or drop-off locations with the driver.</li>
                            <li>Order recipients’ first name, delivery address, and order information with the restaurant or merchant and, for order deliveries, with the delivery person. We may also share ratings and feedback, or other information to the extent required by law, with the restaurant or merchant and, for order deliveries, the delivery person.</li>
                            <li>For driver and delivery persons, we may share data with the travelers, order recipients and restaurants or merchants, including name and photo; vehicle make, model, color, license plate, and vehicle photo; location (before and during trip); average rating provided by consumer; total number of trips; period of time since they signed up to be a driver or delivery person; contact information; and driver or delivery person profile, including compliments and other feedback submitted by past consumers. We also provide travelers and order recipients with receipts containing information such as a breakdown of amounts charged, driver or delivery person first name, photo, and route map. We also include other information on those receipts if required by law. </li>
                            <li>For those who participate in Veel’s referral program, we share certain data of referred consumers, such as trip count, with the user who referred them, to the extent relevant to determining the referral bonus.</li>
                        </ul>
                        <h4 className='heading'>2. At the user’s request</h4>
                        <p>This includes sharing data with:</p>
                        <ul>
                            <li>Veel business partners. For example, if a consumer requests a service through a partnership or promotional offering made by a third party, Veel may share certain data with those third parties. This may include, for example, other services, platforms, apps, or websites that integrate with our APIs; vehicle suppliers or services; those with an API or service with which we integrate; or restaurants, merchants or other Veel business partners and their consumers in connection with promotions, contests, or specialized services.</li>
                            <li>Emergency services: We offer features that enable consumers to share their data with police, fire, and ambulance services in the event of an emergency or after certain incidents. For more information, please see the sections below titled “Choice and Transparency” and “Emergency Data Sharing”.</li>
                        </ul>
                        <h4 className='heading'>3. With the general public</h4>
                        <p>Questions or comments from consumers submitted through public forums such as Veel blogs and Veel social media pages may be viewable by the public, including any data included in the questions or comments submitted by a consumer. </p>
                        <h4 className='heading'>4. With the Veel account owner</h4>
                        <p>If a consumer receives services through an account owned by another party, we may share their trip or order information, including location data, with the owner of that account. This occurs, for example, when:</p>
                        <ul>
                            <li>A traveler uses their employer’s Veel for Business profile, such as when they take trips arranged through the Veel app.</li>
                            <li>A driver or delivery person uses an account owned by or associated with a Veel partner transportation company or restaurant.</li>
                            <li>A traveler takes a trip or a delivery recipient receives an order arranged by a friend or under a family profile.</li>
                        </ul>
                        <h4 className='heading'>6. With Veel service providers and business partners </h4>
                        <p>These include: </p>
                        <ul>
                            <li>Payment processors and facilitators</li>
                            <li>Background check and identity verification providers</li>
                            <li>Cloud storage providers </li>
                            <li>Google, in connection with the use of Google Maps in Veel’s app (see Google’s privacy policy for information on their collection and use of data)</li>
                            <li>Social media companies, including Facebook and TikTok, in connection with Veel’s use of their tools in Veel’s app and website (see Facebook’s privacy policy and TikTok’s privacy policy for information on their collection and use of data)</li>
                            <li>Marketing partners and marketing platform providers, including social media advertising services, advertising networks, third-party data providers, and other service providers to reach or better understand our users and measure advertising effectiveness</li>
                            <p>This includes advertising intermediaries, such as Facebook, that enable Veel to display and measure the effectiveness of personalized ads for third party products that are displayed in Veel’s app. We share data such as advertising or device identifier, hashed email address, approximate location, and current trip or order information, and the intermediaries use this data to provide their services and such other purposes are disclosed in their privacy notices. Users may opt out from ad personalization in the Veel app here.</p>
                            <li>Research partners, including those performing surveys or research projects in partnership with Veel or on Veel’s behalf</li>
                            <li>Vendors that assist Veel to enhance the safety and security of Veel app and services</li>
                            <li>Consultants, lawyers, accountants, and other professional service providers</li>
                            <li>Insurance and financing partners</li>
                            <li>Airports</li>
                            <li>Providers of bike and scooters that can be rented through Veel app, such as Lime</li>
                            <li>Third-party vehicle suppliers, including fleet and rental partners</li>
                        </ul>
                        <h4 className='heading'>7. For legal reasons or in the event of a dispute:</h4>
                        <p>Veel may share users’ data if we believe it’s required by applicable law, regulation, operating license or agreement, legal process or governmental request, or where the disclosure is otherwise appropriate due to safety or similar concerns. This includes sharing data with law enforcement officials, public health officials, other government authorities, airports (if required by the airport authorities as a condition of operating on airport property), or other third parties as necessary to enforce our Terms of Service, user agreements, or other policies; to protect Veel’s rights or property or the rights, safety, or property of others; or in the event of a claim or dispute relating to the use of our services. In the event of a dispute relating to use of another person’s credit card, we may be required by law to share your data, including trip or order information, with the owner of that credit card.</p>
                        <p>This also includes sharing data with others in connection with, or during negotiations of, any merger, sale of company assets, consolidation or restructuring, financing, or acquisition of all or a portion of our business by or into another company. </p>
                        <h4 className='heading'>8. With consent:</h4>
                        <p>Veel may share a user’s data other than as described in this notice if we notify the user and they consent to the sharing.</p>
                        <h2 className='heading'>Data retention and deletion</h2>
                        <p>Veel retains user data for as long as necessary for the purposes described above, including to provide its services and comply with legal obligations. Veel retains user data for as long as necessary for the purposes described above. The period for which we retain user data is determined by the type of data, the category of user to whom the data relates, and the purposes for which we collected the data. The length for which Veel retains user data may further be determined by legal and regulatory requirements, purposes of safety, security, and fraud prevention, or by issues relating to the user’s account such as an outstanding credit or an unresolved claim or dispute.</p>
                        <p>For example,</p>
                        <h4 className='heading'>We retain data:</h4>
                        <ul>
                            <li>For the life of users’ accounts if such data is necessary to provide our services. e.g., user profile information and credentials.</li>
                            <li>For 7 years if necessary to comply with tax requirements. E.g., drivers’ and delivery persons’ trip or delivery location information.</li>
                            <li>For defined periods as necessary for purposes of safety or fraud prevention. E.g., we retain incomplete driver applications for 1 year, and reject driver applications for 7 years.</li>
                            <li>After requests for account deletion if necessary for purposes of safety, security, fraud prevention or compliance with legal requirements, or because of issues relating to the user’s account (such as an outstanding credit or an unresolved claim or dispute).</li>
                        </ul>
                        <p>Users may request deletion of their accounts at any time. Veel may retain user data after a deletion request due to legal or regulatory requirements or for reasons stated in this policy Users may request deletion of their account at any time through the Settings, Privacy menus in the Veel app, or through Veel’s website.</p>
                        <p>Following an account deletion request, Veel deletes the user’s account and data, unless they must be retained due to legal or regulatory requirements, for purposes of safety, security, and fraud prevention, or because of an issue relating to the user’s account such as an outstanding credit or an unresolved claim or dispute. Because we are subject to legal and regulatory requirements relating to drivers and delivery persons, this generally means that we retain their account and data for the applicable statutory retention period after a deletion request. For riders and order recipients, their data is generally deleted within 90 days of a deletion request, except where retention is necessary for the above reasons.</p>
                        <h2 className='heading'>Choice and transparency</h2>
                        <p>Veel enables users to access and/or control data that Veel collects, including through: </p>
                        <ul>
                            <li>Privacy settings</li>
                            <li>Device permissions</li>
                            <li>In-app ratings pages</li>
                            <li>Veel also enables users to request access to or copies of their data, make changes or updates to their accounts, request deletion of their accounts, or request that Veel restrict its processing of user data.</li>
                        </ul>
                        <h3 className='heading'>A. Privacy Settings</h3>
                        <p>Riders and order recipients can establish or modify their choices for location data collecting and sharing, emergency data sharing, and notifications in the Veel app's Settings, Privacy menu. Additionally, a web version of our Privacy Center is accessible.</p>
                        <ul>
                            <li><h4 className='heading'>Location data collection (riders and order recipients):</h4></li>
                            <p>Riders and order recipients can enable/disable Veel to collect location data from their mobile devices through their device settings, which can be accessed via the Settings, Privacy, Location menu. </p>
                            <li><h4 className='heading'>Share Live Location (Riders): </h4></li>
                            <p>Riders can enable/disable Veel to share their real-time location data from their mobile devices with their drivers through their device settings, which can be accessed via the Settings, Privacy, Location menu.</p>
                            <li><h4 className='heading'>Emergency Data Sharing:</h4></li>
                            <p>Riders may enable Veel to share real-time location data from their mobile devices with emergency police, fire, and ambulance services. Such data may include approximate location at the time the emergency call was placed; the car’s make, model, color, and license plate information; the rider’s name and phone number; pickup and drop-off locations; and the driver’s name. Riders may enable/disable this feature via the Settings, Privacy, Location menu, or the Safety Center.</p>
                            <p>Drivers and delivery persons can also enable/disable Emergency Data Sharing via the App settings, Emergency Data Sharing menu, or the Safety Toolkit.</p>
                            <li><h4 className='heading'>Notifications: account and trip updates</h4></li>
                            <p>Veel  provides users with trip status notifications and updates related to activity on their account. These notifications are a necessary part of using the Veel app and cannot be disabled. However, users may choose the method by which they receive these notifications through the Settings, Privacy menu.</p>
                            <li><h4 className='heading'>Notifications: discounts and news</h4></li>
                            <p>Users may enable Veel to send push notifications about discounts and news from Veel. Push notifications may be enabled or disabled through the Settings, Privacy menus in the Veel app.</p>
                            <li><h4 className='heading'>Communications from restaurants and merchants:</h4></li>
                            <p>While placing an order in the Veel Express Delivery, users may opt-in to share their contact details with certain restaurants or merchants in order to receive communications from them. Those who opt-in may choose to cease receiving such communications through the Settings, Account, Data Sharing menus in the Veel Express Delivery.</p>
                        </ul>
                        <h3 className='heading'>B. Device permissions</h3>
                        <p>Most mobile device platforms (iOS, Android, etc.) have defined certain types of device data that apps cannot access without the device owner’s permission, and these platforms have different methods for how that permission can be obtained. Please check the available settings on your device or check with your provider. </p>
                        <h3 className='heading'>C. In-app ratings pages</h3>
                        <p>After every trip, drivers and riders are able to rate each other on a scale from 1 to 5. An average of those ratings is associated with a user’s account and is displayed to other users for whom they provide or receive services. For example, rider ratings are available to drivers from whom they request transportation, and driver ratings are available to their riders.</p>
                        <p>This 2-way system holds everyone accountable for their behavior. Accountability helps create a respectful, safe environment for drivers and riders. Riders can see their average rating in the main menu of the Veel app, and also access a breakdown of their average rating in Veel’s Privacy Center. Drivers can see their average rating after tapping their profile photo in the Veel app. Delivery persons may also be rated by order recipients, restaurants and merchants.</p>
                        <h3 className='heading'>D. In-app calling or text pages</h3>
                        <p>After posting a trip, consumers can call or text its driver via in-app calling and in-app features.It will be free of cost for 30s call and free messages. Similarly, drivers also can call on arrival  which is also free for 30s. Which is also being recorded for quality purposes</p>
                        <h3 className='heading'>E. Marketing and advertising choices</h3>
                        <p>Veel provides users with the following choices regarding how their data is used for purposes of marketing and advertising:</p>
                        <ul>
                            <li><h4 className='heading'>Personalized marketing communications from Veel:</h4></li>
                            <p>Users may choose here whether Veel may use their data to send personalized communications (such as, emails, push notifications, and in-app calls and messages) about Veel services. Users may also choose here whether to receive any marketing emails or push notifications from Veel.</p>
                            <li><h4 className='heading'>Veel ads:</h4></li>
                            <p>Users may choose here whether their data is used and shared with advertising partners to deliver personalized ads about Veel products and services on third party apps and websites, or to measure the effectiveness of such ads.</p>
                            <li><h4 className='heading'>Restaurant and merchant ads:</h4></li>
                            <p>Users may choose here whether Veel may use their order and search history to display personalized ads for restaurants or merchants that are available on Veel Express Delivery</p>
                            <li><h4 className='heading'>Third party ads:</h4></li>
                            <p>Users may choose here whether Veel may use their data to display ads for third party products or services that are personalized based on users’ observed or inferred interests and characteristics.</p>
                            <li><h4 className='heading'>Cookies and related technologies:</h4></li>
                            <p>For information on how to control Veel’s use of cookies and related technologies, including for purposes of displaying personalized ads, please see our Cookie Notice.</p>
                        </ul>
                        <h3 className='heading'>F. User data requests</h3>
                        <p>Veel provides users with a variety of ways to learn about, control, and submit questions and comments about Veel’s handling of their data</p>
                        <ul>
                            <li><h4 className='heading'>Accessing data:</h4></li>
                            <p>Users can access data including their profile data and trip or order history through the Veel apps or via Veel’s website. Users can also use our Explore Your Data feature to view an online summary of information about their account, such as number of trips or orders, rating, rewards status, and number of days since they’ve been a Veel user. Users can also request access to their data here.</p>
                            <li><h4 className='heading'>Data portability:</h4></li>
                            <p>Users can request a copy of their data using our Download Your Data tool. For an overview of the data available through that tool, please click here. Users may also request a copy of their data here.</p>
                            <li><h4 className='heading'>Changing or updating data:</h4></li>
                            <p>Users can edit the name, phone number, email address, payment method, and photo associated with their account through the Settings menu in veel’s apps or driver portal. Users may also request to update or correct their data here.</p>
                            <li><h4 className='heading'>Deleting data:</h4></li>
                            <p>Users may request deletion of their account at any time through Veel’s Privacy Center, or in the Settings, Privacy menus in the Veel app.</p>
                        </ul>
                        <br /><br />
                    </div>
                </div>
            </div>
            <Copyrights/>
        </>
    )
}
