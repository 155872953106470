import { mdiChevronRight } from '@mdi/js'
import Icon from '@mdi/react'
import React from 'react'

export default function Main() {
  return (
    <section className="home-2 home-slider" id="home">
      <div className="box">
        <div className="wave -one"></div>
        <div className="wave -two"></div>
        <div className="wave -three"></div>
      </div>
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-6">
            <div className="home-heading">
              <div className="d-flex justify-content-end mt-5 pt-md-5">
                <span className="badge bg-soft-primary fs-2 rounded-pill mb-3"><span className="greenn">آپکا</span> <span>اپنا</span> <span className="redd">ہمسفر</span></span>
              </div>
              <h3 className='text-white'>Pakistan’s 1st Ever <br /> Safest & Premium
                Mobility</h3>
              <p className="home-title"></p>
              <p className="text-muted fs-5">Web 3.0 Artificial Intelligence Powered E-Hailing app, having decentralized platform to empower both Consumers &
                partners with unique retention strategies. It will provide Eco-Friendly/EV
                fleets with QR Tag operators to curb the rising CO2 emission & road
                accidents in Pakistan. It will serve mobility, delivery and cashless payment
                gateways to all age groups from kids to youngster to elders.</p>
              <div className='row'>
                <div className='col-md-6'>
                  <h4 className="my-3 ms-3">Veel - Driver <br /> Entrepreneur</h4>
                  <div className="mt-3 d-flex">
                    {/* <a href="/">
                  <img src="assets/images/apple.png" className="img-responsive" width={80} height={90} alt="apple" />
                </a> */}
                    <a href="https://play.google.com/store/apps/details?id=com.veel.drivers" className='ms-3'>
                      <button className='btn btn-block bg-gradiant register me-3 text-black px-5 fs-6'>Install Driver <Icon path={mdiChevronRight}
                        size={1}
                        className='text-black' /></button>
                    </a>
                  </div>
                </div>
                <div className='col-md-6'>
                  <h4 className="my-3 ms-3">Veel - Safest <br /> Premium Mobility</h4>
                  <div className="mt-3 d-flex">
                    {/* <a href="/">
                  <img src="assets/images/apple.png" className="img-responsive" width={80} height={90} alt="apple" />
                </a> */}
                    <a href="https://play.google.com/store/apps/details?id=com.veel.users" className='ms-3'>
                      <button className='btn btn-block bg-gradiant register me-3 text-black px-5 fs-6'>Install User<Icon path={mdiChevronRight}
                        size={1}
                        className='text-black' /></button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-5">
            <video muted autoPlay controls width="100%" style={{ borderRadius: '20px' }} height="100%" loop playsInline className="img-fluid">
              <source src="assets/images/home.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </section>
  )
}
