import { mdiFacebook, mdiInstagram, mdiLinkedin, mdiTwitter, mdiYoutube } from '@mdi/js'
import Icon from '@mdi/react'
import React from 'react'

export default function Footer() {
    return (
        <section className="footer overflow-hidden">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 text-center">
                        <a className="navbar-brand logo text-uppercase" href="/">
                            <img src="assets/images/logoo.png" className="logo-dark mt-5" alt="" width={300} />
                        </a>
                        <div className="footer-icon mt-4 d-flex justify-content-center">
                            <div className="d-flex align-items-center">
                                <a href="https://www.facebook.com/profile.php?id=100088755792048" className="me-2 avatar-sm text-center"
                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Facebook">
                                    <Icon path={mdiFacebook}
                                        size={1}
                                        className='f-24 align-middle text-primary' />
                                </a>
                                <a href="https://twitter.com/veelmobility" className="mx-2 avatar-sm text-center"
                                    data-bs-toggle="tooltip" data-bs-placement="top" title="twitter">
                                    <Icon path={mdiTwitter}
                                        size={1}
                                        className='f-24 align-middle text-primary' />
                                </a>
                                <a href="https://instagram.com/veelmobility" className="mx-2 avatar-sm text-center"
                                    data-bs-toggle="tooltip" data-bs-placement="top" title="instagram">
                                    <Icon path={mdiInstagram}
                                        size={1}
                                        className='f-24 align-middle text-primary' />
                                </a>
                                <a href="https://www.linkedin.com/company/veel-mobility/about/" className="mx-2 avatar-sm text-center">
                                    <Icon path={mdiLinkedin}
                                        size={1}
                                        className='f-24 align-middle text-primary' />
                                </a>
                                <a href="https://www.youtube.com/@veelmobility" className="mx-2 avatar-sm text-center">
                                    <Icon path={mdiYoutube}
                                        size={1}
                                        className='f-24 align-middle text-primary' />
                                </a>
                            </div>
                        </div>
                    </div>
                    <h4 className="pt-5 text-center" style={{ color: 'gray' }}>Project by <br /> MuMM MENTOR GROUP & SWATI HOLDINGS</h4>
                </div>
            </div>
        </section>
    )
}
