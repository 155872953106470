import React from 'react'
import { useEffect } from 'react';
import NavigationBar from '../components/NavigationBar';
import Copyrights from '../components/Copyrights';

function TermsConditions() {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'Terms & Condition | Veel Mobility';
    });

  return (
      <>
          <NavigationBar />
          <div className='container mt-5 pt-5'>
              <div className="d-flex justify-content-center">
                  <img
                      className="pt-5"
                      src="./assets/images/logo.png"
                      alt="logo"
                      width={150}
                  />
              </div>
              <div className='row mt-3'>
                  <div className='col-12'>
                      
                      <h2 className='heading'>Contractual  Relationship</h2>
                      <p>These  "Terms  &  Conditions"  administer  the  entrance  or  use  by  you,  a  person,  from  inside  any  nation  in  the  realm  of  utilizations,  sites,  substance,  items,  and  administrations  (the  "Administrations").  It  would  be  ideal  if  you  read  these  terms and conditions carefully  before accessing the services.</p>
                      <p>Your  entrance  and  utilization  of  the  Services  comprises  your  consent  to  be  bound  by  these  Terms  &  Conditions,  which  builds  up  a  legally  binding  connection  amidst  you  and  veel.ai.  In  the  event  that  you  don't  consent  to  these  Terms  &  Conditions,  you  may  not  access  or  utilize  the  Services.  veel.ai  may  quickly  end  services  as  for  you,  or  by  and  large  stop  offering  or  deny  access  to  the  Services  or  any  segment  thereof,  whenever  for  any  reason.</p>
                      <p>Supplemental  terms  may  apply  to  specific  services,  during  a  specific  occasion,  movement  or  advancement,  and  such  supplemental  terms  will  be  unveiled  to  you  regarding  the  material  services.  Supplemental  terms  notwithstanding,  will  be  esteemed  a  piece  of,  the  Terms  &  Conditions  for  the  motivations  behind  the  material  services.  Supplemental  terms  will  beat  these  Terms  &  Conditions  in  case  of  a  contention  regarding  the  pertinent  services.</p>
                      <p>veel.ai  may  correct  the  Terms  &  Conditions  identified  with  the  services  every  once  in  a  while.  Alterations  will  be  done  upon  veel.ai  posting  of  updated  Terms  &  Conditions  at  this  area  or  the  changed  arrangements  or  supplemental  terms  on  the  relevant  Service.  Your  proceeding  with  access  or  utilization  of  the  services  after  such  presenting  establishes  your  assent  to  be  bound  by  the  Terms  &  Conditions,  as  altered.</p>
                      <p>veel.ai  may  give  to  a  cases  processor  or  a  safety  net  provider  any  fundamental  data  (counting  your  contact  data)  if  there  is  a  dissension,  debate  or  strife,  which  may  incorporate  a  mishap,  including  you  and  a  Third  Party  Provider  and  such  data  or  information  is  important  to  determine  the  grumbling,  question  or  struggle.</p>

                      <h2 className='heading'>License</h2>
                      <p>Subject  to  your  consistency  with  these  Terms  &  Conditions,  veel.ai  awards  you  a  constrained,  non-elite,  non-sublicensable,  revocable,  non-transferrable  permit  to:  (I)  access  and  utilize  the  Applications  on  your  own  gadget  exclusively  regarding  your  utilization  of  the  Services;  and  (ii)  access  and  utilize  any  substance,  data  and  related  materials  that  might  be  made  accessible  through  the  Services,  for  each  situation  exclusively  for  your  own,  noncommercial  use.  Any  rights  not  explicitly  allowed  in  this  are  held  by  veel.ai  licensors.</p>

                      <h2 className='heading'>Restrictions</h2>
                      <p>You  may  not:  (I)  evacuate  any  copyright,  trademark  or  other  exclusive  notification  from  any  bit  of  the  Services;  (ii)  replicate,  change,  get  ready  subsidiary  works  dependent  on,  circulate,  permit,  rent,  move,  exchange,  exchange,  freely  show,  openly  perform,  transmit,  stream,  communicate  or  generally  misuse  the  Services  aside  from  as  explicitly  allowed  by  veel.ai;  (iii)  decompile,  figure  out  or  dismantle  the  Services  with  the  exception  of  as  might  be  allowed  by  material  law;  (iv)  connection  to,  mirror  or  edge  any  bit  of  the  services;  (v)  cause  or  dispatch  any  projects  or  contents  to  scrape,  ordering,  looking  over,  or  generally  information  mining  any  part  of  the  Services  or  unduly  loading  or  frustrating  the  task  as  well  as  usefulness  of  any  part  of  the  Services;  or  (vi)  endeavor  to  increase  unapproved  access  to  or  disable  any  part  of  the  Services  or  its  related  frameworks  or  systems.</p>

                      <h2 className='heading'>Ownership</h2>
                      <p>The  Services  and  rights  will  remain  veel.ai  property  licensors.  Neither  these  Terms  &  Conditions,  nor  your  utilization  of  the  Services  pass  on  or  concede  to  you  any  rights:  (I)  in  or  identified  with  the  Services  aside  from  the  constrained  permit  allowed  above;  or  (ii)  to  utilize  or  reference  in  any  way  veel.ai  organization  names,  logos,  item  and  administration  names,  trademarks  or  administrations  marks  or  those  of  veel.ai  licensors.</p>
                      
                      <h2 className='heading'>Your  Use  of  the  Services</h2>
                      <h3 className='heading'>User  Accounts</h3>
                      <p>To  utilize  most  parts  of  the  Services,  you  should  enroll  for  and  keep  up  a  functioning  individual  client  Services  ("Account").  You  should  be  18+  years  old,  to  acquire  an  Account.  Record  enlistment  expects  you  to  submit  to  veel.ai  individual  data,  for  example,  your  name,  address,  cell  phone  number  and  age,  and  additionally  no  less  than  one  substantial  installment  technique  (either  a  charge  card  or  acknowledged  installment  accomplice).  You  consent  to  keep  up  exact,  finish,  and  up  and  coming  data  in  your  Account.  Your  inability  to  keep  up  exact,  finish,  and  up  and  coming  Account  data,  including  having  an  invalid  or  lapsed  installment  strategy  on  document,  may  result  in  your  powerlessness  to  access  and  utilize  the  Services  or  veel.ai  end  these  Terms  &  Conditions  with  you.  You  are  in  charge  of  all  activity  happens  under  your  Account,  and  you  consent  to  keep  up  the  security  and  mystery  of  your  Account  username  and  secret  key  consistently.  </p>

                      <h3 className='heading'>User  Requirements  and  Conduct</h3>
                      <p>The  services  aren’t  accessible  for  use  by  people  younger  than  18.  You  may  not  approve  outsiders  to  utilize  your  Account,  and  you  may  not  permit  people  younger  than  18  to  get  transportation  or  coordination  administrations  from  Third  Party  Providers.  You  may  not  relegate  or  generally  exchange  your  Account  to  some  other  individual  or  substance.  You  consent  to  follow  every  single  pertinent  law  when  utilizing  the  services,  and  you  may  just  utilize  the  services  for  legal  purposes.  You  won't,  in  your  utilization  of  the  Services,  cause  disturbance,  irritation,  burden,  or  property  harm,  regardless  of  whether  to  the  Third  Party  Provider  or  some  other  gathering.  In  specific  occurrences  you  might  be  solicited  to  give  confirmation  from  identity  to  access  or  utilize  the  Services,  and  you  concur  that  you  might  be  denied  access  to  or  utilization  of  the  Services  in  the  event  that  you  decline  to  give  evidence  of  personality.</p>

                      <h3 className='heading'>Promotional  Codes</h3>
                      <p>veel.ai  offers  limited  time  codes  that  might  be  reclaimed  for  Account  credit,  or  different  highlights  or  advantages  identified  with  the  Services  as  well  as  a  Third  Party  Provider's  administrations,  subject  to  any  extra  terms  that  veel.ai  sets  up  on  a  for  every  special  code  premise  ("Promo  Codes").  You  concur  that  Promo  Codes:</p>
                      <ol type='I'>
                          <li>must  be  utilized  for  the  target  group  and  reason,  and  in  a  legal  way;</li>
                          <li>may  not  be  copied,  sold  or  moved  in  any  way,  or  made  accessible  to  the  overall  population  (regardless  of  whether  presented  on  an  open  frame  or  something  else),  except  if  explicitly  allowed  by  veel.ai;</li>
                          <li>might  be  invalidated  by  veel.ai  whenever  for  any  reason  without  risk;</li>
                          <li>may  just  be  utilized  as  per  the  explicit  terms  that  veel.ai  sets  up  for  such  Promo  Code;  (v)  are  not  substantial  for  money;</li>
                          <li>may  lapse  preceding  your  utilization.  veel.ai  maintains  whatever  authority  is  needed  to  retain  or  deduct  credits  or  different  highlights  or  advantages  acquired  using  Promo  Codes  by  you  or  some  other  client  if  veel.ai  decides  or  trusts  that  the  utilization  or  reclamation  of  the  Promo  Code  was  in  blunder,  deceitful,  unlawful,  or  disregarding  the  pertinent  Promo  Code  terms  or  these  Terms  &  Conditions.</li>
                      </ol>

                      <h3 className='heading'>User  Provided  Content</h3>
                      <p>veel.ai  may  allow  you  now  and  again  to  submit,  transfer,  distribute  or  generally  make  accessible  to  the  Services  literary,  sound,  or  potentially  visual  substance  and  data,  including  critique  and  criticism  identified  with  the  Services,  commencement  of  help  solicitations,  and  accommodation  of  passages  for  rivalries  and  advancements  ("User  Content").  Any  User  Content  given  by  you  remains  your  property.  In  any  case,  by  giving  User  Content  to  veel.ai,  you  give  an  around  the  world,  unending,  permanent,  transferrable,  eminence  free  permit,  with  the  privilege  to  sublicense,  to  utilize,  duplicate,  adjust,  make  subordinate  works  of,  appropriate,  openly  show,  freely  perform,  and  generally  misuse  in  any  way  such  User  Content  in  all  arrangements  and  dissemination  channels  currently  known  or  in  the  future  contrived  (incorporating  into  association  with  the  Services  and  veel.ai  the  same  old  thing  and  on  outsider  locales  and  administrations),  without  further  notice  to  or  assent  from  you,  and  without  the  prerequisite  of  installment  to  you  or  some  other  individual  or  element.</p>
                      <p>You  speak  to  and  warrant  that:  (I)  you  either  are  the  sole  and  select  proprietor  of  all  User  Content  or  you  have  all  rights,  licenses,  assets  and  discharges  important  to  give  veel.ai  the  permit  to  the  User  Content  as  put  forward  above;  and  (ii)  neither  the  User  Content  nor  your  accommodation,  transferring,  distributing  or  generally  making  accessible  of  such  User  Content  nor  veel.ai  utilization  of  the  User  Content  as  allowed  in  this  will  encroach,  abuse  or  damage  an  outsider's  protected  innovation  or  exclusive  rights,  or  privileges  of  exposure  or  security,  or  result  in  the  infringement  of  any  relevant  law  or  direction.</p>
                      <p>You  consent  to  not  give  User  Content  that  is  slanderous,  derogatory,  contemptuous,  savage,  profane,  obscene,  unlawful,  or  generally  hostile,  as  controlled  by  veel.ai  in  its  sole  watchfulness,  regardless  of  whether  such  material  might  be  secured  by  law.  veel.ai  may,  however  will  not  be  committed  to,  survey,  screen,  or  evacuate  User  Content,  at  veel.ai  sole  prudence  and  whenever  and  for  any  reason,  without  notice  to  you.</p>

                      <h3 className='heading'>Network  Access  and  Devices</h3>
                      <p>You  are  in  charge  of  getting  the  information  arranged  to  be  important  to  utilize  the  Services.  Your  portable  system's  information  and  informing  rates  and  expenses  may  apply  on  the  off  chance  that  you  access  or  utilize  the  Services  from  a  remote  empowered  gadget  and  you  will  be  in  charge  of  such  rates  and  charges.  You  are  in  charge  of  getting  and  refreshing  perfect  equipment  or  gadgets  important  to  access  and  utilize  the  Services  and  Applications  and  any  updates  thereto.  veel.ai  does  not  ensure  that  the  Services,  or  any  segment  thereof,  will  work  on  a  specific  equipment  or  gadgets.  Also,  the  Services  might  be  liable  to  glitches</p>

                      <h3 className='heading'>Payment</h3>
                      <p>You  comprehend  that  utilization  of  the  Services  may  result  in  charges  to  you  for  the  administrations  or  merchandise  you  get  from  a  Third  Party  Provider  ("Charges'').  After  you  have  gotten  administrations  or  products  acquired  through  your  utilization  of  the  Service,  veel.ai  will  encourage  your  installment  of  the  material  Charges  for  the  benefit  of  the  Third  Party  Provider  thusly  Third  Party  Provider's  constrained  installment  accumulation  operator.  Installment  of  the  Charges  in  such  way  will  be  viewed  as  equivalent  to  installment  made  straightforwardly  by  you  to  the  Third  Party  Provider.  Charges  will  be  comprehensive  of  relevant  expenses  where  required  by  law.  Charges  paid  by  you  are  conclusive  and  non-refundable,  except  if  generally  dictated  by  veel.ai.  You  hold  the  privilege  to  ask  for  lower  Charges  from  a  Third  Party  Provider  for  administrations  or  products  gotten  by  you  from  such  Third  Party  Provider  at  the  time  you  get  such  administrations  or  merchandise.  veel.ai  will  react  in  like  manner  to  any  demand  from  a  Third  Party  Provider  to  adjust  the  Charges  for  a  specific  administration  or  great.</p>
                      <p>All  Charges  are  expected  quickly  and  installment  will  be  encouraged  by  veel.ai  utilizing  the  favored  installment  technique  assigned  in  your  Account,  after  which  veel.ai  will  send  you  a  receipt  by  email.  In  the  event  that  your  essential  Account  installment  strategy  is  resolved  to  be  terminated,  invalid  or  generally  not  ready  to  be  charged,  you  concur  that  veel.ai  may,  as  the  Third  Party  Provider's  constrained  installment  accumulation  specialist,  utilize  an  optional  installment  technique  in  your  Account,  if  accessible.</p>
                      <p>This  installment  structure  is  planned  to  completely  repay  the  Third  Party  Provider  for  the  administrations  or  products  given.  Aside  from  as  for  cab  transportation  administrations  asked  for  through  the  Application,  veel.ai  does  not  assign  any  bit  of  your  installment  as  a  tip  or  tip  to  the  Third  Party  Provider.  Any  portrayal  by  veel.ai  (on  veel.ai  site,  in  the  Application,  or  in  veel.ai  advertising  materials)  such  that  tipping  is  "deliberate,"  "not  required,"  as  well  as  "included"  in  the  installments  you  make  for  administrations  or  products  gave  isn't  proposed  to  recommend  that  veel.ai  gives  any  extra  sums,  past  those  depicted  above,  to  the  Third  Party  Provider.  </p>

                      <h3 className='heading'>General</h3>
                      <p>You  may  not  dole  out  or  move  these  Terms  &  Conditions  in  entire  or  partially  without  veel.ai  endorsement.  You  give  your  endorsement  to  veel.ai  for  it  to  appoint  or  move  these  Terms  &  Conditions  in  entire  or  to  some  extent,  including  to:  (I)  a  backup  or  associate;  (ii)  an  acquirer  of  veel.ai  value,  business  or  resources;  or  (iii)  a  successor  by  merger.  </p>
                      <p>On  the  off  chance  that  any  arrangement  of  these  Terms  &  Conditions  is  held  to  be  unlawful,  invalid  or  unenforceable,  in  entire  or  to  some  extent,  under  any  law,  such  arrangement  or  part  thereof  will  to  that  degree  be  regarded  not  to  frame  some  portion  of  these  Terms  &  Conditions  but  rather  the  lawfulness,  legitimacy  and  enforceability  of  alternate  arrangements.  These  Terms  &  Conditions  comprise  the  whole  ascension  and  comprehension  of  the  gatherings  as  for  its  topic  and  replaces  and  overrides  all  earlier  or  contemporaneous  understandings  or  endeavors  with  respect  to  such  topic. </p>

                      <br /><br />
                  </div>
              </div>
          </div>
          <Copyrights />
      </>
  )
}

export default TermsConditions
