import React from 'react'

export default function Cta() {
  return (
    <section className="section cta" id="cta">
            <div className="container position-relative">
                <div className="row">
                    <div className="col-xl-6 col-lg-10">
                        <div className="py-5">
                            <h1 className="display-4">Care & Safety</h1>
                            <p className="text-muted mt-3 f-18">Care for Passengers and Solo/Lady/Trans is a core value for Veel. Your Safety is our main concern and
                                your comfort is our top priority.</p>
                            <div className="d-flex mt-4 ">
                                {/* <div className="app-store">
                                    <a href="/"><img src="assets/images/img-appstore.png" alt="apple"
                                            className="img-fluid"/></a>
                                </div> */}
                                <div className="googleplay">
                                    <a href="https://play.google.com/store/apps/details?id=com.veel.users">
                                        <img src="assets/images/img-googleplay.png" alt="google"
                                            className="img-fluid ms-3"/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="cta-phone-image mt-md-5 pt-md-5 mt-3 ms-md-5 ps-md-5 ms-0 ps-0 text-center">
                            <img src="assets/images/care.jpeg" alt="care" className="img-fluid" width="300" height="300" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}
