import React, { useEffect } from 'react';
import NavigationBar from '../components/NavigationBar';
import Main from '../components/Main';
import Mobile from '../components/Mobile';
import AboutUs from '../components/AboutUs';
import Exclusive from '../components/Exclusive';
import Partners from '../components/Partners';
import AppScreenshots from '../components/AppScreenshots';
import FounderVision from '../components/FounderVision';
import Cta from '../components/Cta';
import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';
import Copyrights from '../components/Copyrights';

export default function Home() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'Veel Mobility';
    });
    return (
        <>
            <NavigationBar/>
            <div className="overflow-hidden-x">
                <Main />
                <Mobile />
                <AboutUs />
                <Exclusive />
                <Partners />
                <AppScreenshots />
                <FounderVision />
                <Cta />
                <ContactUs />
                <Footer/>
            </div>
            <Copyrights/>
        </>
    )
}
