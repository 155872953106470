import React, { useEffect } from "react";
import "./style.css";

export default function QrCode() {

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Qr Operations | Veel';
  });

  return (
    <div className="qr-code">
      <div className="d-flex justify-content-center">
        <img
          className="pt-5"
          src="./assets/images/logo.png"
          alt="logo"
          width={200}
        />
      </div>
      <div className="max-w max-h p-5 qr-card rounded-lg shadow-md">
        <h1 className="text-white fw-bold text-lg mb-2 uppercase">Solo Details</h1>
        <div className="d-flex justify-content-center">
          <img
            src="https://www.creative-tim.com/learning-lab/tailwind-starter-kit/img/team-2-800x800.jpg"
            alt="solo profile"
            className="shadow rounded-circle border-none"
            width={150}
            height={150}

          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row mt-3">
                <div className="col-md-6">
                  <button
                    className="qr-button mt-2 px-6 py-3"
                  >
                    First Name
                  </button>
                </div>
                <div className="col-md-6">
                  <button
                    className="qr-button mt-2 px-6 py-3"
                  >
                    Last Name
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <button
                className="qr-button mt-2 px-6 py-3"
              >
                Tier
              </button>
            </div>
            <div className="col-md-12">
              <button
                className="qr-button mt-2 px-6 py-3"
              >
                Rating
              </button>
            </div>
            <div className="col-md-12">
              <button
                className="qr-button mt-2 px-6 py-3"
              >
                Vehicle Name
              </button>
            </div>
            <div className="col-md-12">
              <button
                className="qr-button mt-2 px-6 py-3"
              >
                Number Plate
              </button>
            </div>
            <div className="col-md-12">
              <button
                className="qr-button mt-2 px-6 py-3"
              >
                Maintenance Report
              </button>
            </div>
          </div>
        </div>
        <p className="mt-4 mb-0 font-normal text-white text-center">
          Veel AI Technologies &copy; All Rights Reserved 2023
        </p>
      </div>
    </div>
  );
}
