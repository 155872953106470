import React from 'react'

export default function FounderVision() {
    return (
        <section className="section team bg-light" id="about">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="sec-hd">
                            <span className="heading"></span>
                            <h2 className="sec-title">ABOUT US</h2>
                            <span className="heading"></span>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="tab-container effectTab-header" id="style_10">
                            <div className="wrapper">
                                <div className="row">
                                    <div className="col-12 tab">
                                        <div className="row">
                                            <div className="col-md-6 box-tab hide">
                                                <div className="effect effect-ten">
                                                    <img src="assets/images/mumm.jpg" className="img-fluid" alt="mumm-ceo" />
                                                    <div className="tab-text d-flex justify-content-center">
                                                        <h2 style={{ alignSelf: 'flex-end'}}>Muhammad Usman Malik</h2>
                                                        <div className="icons-block"> <a
                                                            href="https://www.linkedin.com/in/muhammad-usman-38566795/" className="social-icon-2"><i
                                                                className="mdi mdi-linkedin"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 box-tab hide">
                                                <h3 className='text-center'>FOUNDER VISION</h3>
                                                <p className='fs-5 mt-2 text-center'>
                                                    After 03 Years of our R&D, We have drafted the Monopolist Strategies for our startup, not to become the No. 01 but the Only One in the World as a First Class Lifestyle E-Hailing Brand <br /><span className='heading'>- NEXT GLOBAL BIG THING -</span>
                                                    <br/><br/>
                                                    My vision is to add value in the world, in every sector of humans and other creatures’ life with my startup. I want to serve all age groups from kids to youngsters, then adults to elders.
                                                    I want to provide my customers with safest & premium services, I want to empower women, I want to introduce Kids Engagement Strategies as they are all my future customers, I want to add value in Environmental Protection and I want to own CSR Activities in my startup to serve the entire Community and rest of the world.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-5">
                                        <div className="row">
                                            <div className="col-md-4 mt-3">
                                                <div className="card text-center">
                                                    <div className="card-header p-5">
                                                        <img
                                                            src="assets/images/heart.png"
                                                            alt="heart" className="w-100 img-fluid" />
                                                    </div>
                                                    <hr />
                                                    <div className="card-body">
                                                        <h3 className="card-text">To provide my Customer Safe & Premium Services</h3>
                                                    </div>
                                                    <div className="card-footer text-muted"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mt-3">
                                                <div className="card text-center">
                                                    <div className="card-header p-5">
                                                        <img
                                                            src="assets/images/planet-earth.png"
                                                            alt="planet" className="w-100 img-fluid" />
                                                    </div>
                                                    <hr />
                                                    <div className="card-body">
                                                        <h3 className="card-text">To add value in Environmental Protection</h3>
                                                    </div>
                                                    <div className="card-footer text-muted"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mt-3">
                                                <div className="card text-center">
                                                    <div className="card-header p-5">
                                                        <img
                                                            src="assets/images/feminine.png"
                                                            alt="feminine" className="w-100 img-fluid" />
                                                    </div>
                                                    <hr />
                                                    <div className="card-body">
                                                        <h3 className="card-text">To add value in Women Empowerment</h3>
                                                    </div>
                                                    <div className="card-footer text-muted"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mt-3 offset-md-2">
                                                <div className="card text-center">
                                                    <div className="card-header p-0">
                                                        <img
                                                            src="assets/images/kids.jpeg"
                                                            alt="kids" className="w-100 img-fluid" />
                                                    </div>
                                                    <hr />
                                                    <div className="card-body">
                                                        <h3 className="card-text">To promote Kids Engagement Services</h3>
                                                    </div>
                                                    <div className="card-footer text-muted"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mt-3">
                                                <div className="card text-center">
                                                    <div className="card-header p-0">
                                                        <img
                                                            src="assets/images/csr.png"
                                                            alt="csr" className="w-100 img-fluid" />
                                                    </div>
                                                    <hr />
                                                    <div className="card-body">
                                                        <h3 className="card-text">To promote Corporate Social Responsibilities activities</h3>
                                                    </div>
                                                    <div className="card-footer text-muted"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
